Cake.create('app', '#app', {
    data(){
        // console.log(this);
        let toolbarConfig = [
            {id:'save', display:'Save', icon:'fa-save', hasIcon:'yes'},
            {id:'search', display:'Search', icon:'fa-search', hasIcon:'yes'},
            {id:'addRecord', display:'Add Record', icon:'fa-plus', hasIcon:'yes'},
            {id:'sync', display:'Sync', icon:'fa-retweet', hasIcon:'yes'},
            {id:'download', display:'Download', icon:'fa-download', hasIcon:'yes'},
            {id:'upload', display:'Upload', icon:'fa-download', hasIcon:'no'},
            {id:'filter', display:'Filter', icon:'fa-filter', hasIcon:'yes'},
            {id:'print', display:'Print', icon:'fa-print', hasIcon:'yes'},
            {id:'validate_all', display:'Validate All', icon:'fa-check', hasIcon:'yes'},
            {id:'validate_center', display:'Validate Center', icon:'fa-check', hasIcon:'yes'},
            {id:'reset', display:'Reset', icon:'fa-retweet', hasIcon:'yes'},
            {id:'today', display:'Today', icon:'', hasIcon:'no'},
            {id:'yesterday', display:'Yesterday', icon:'', hasIcon:'no'},
            {id:'current_week', display:'This Week', icon:'', hasIcon:'no'},
            {id:'previous_week', display:'Last Week', icon:'', hasIcon:'no'},
            {id:'current_month', display:'This Month', icon:'', hasIcon:'no'},
            {id:'previous_month', display:'Last Month', icon:'', hasIcon:'no'},
        ];


        let betFilterConfig = [
            {id:'gamesmart', display:'Game Smart', icon:''},
            {id:'dynasty', display:'Dynasty', icon:''},
        ];

        let radioConfig = [
            {id:'profile', display:'Profile', ctx: 'is-link',icon:'fa-user-alt'},
            {id:'edit', display:'Edit', ctx: 'is-link',icon:'fa-pencil-alt'},
            {id:'delete', display:'Delete', ctx: 'is-danger', icon:'fa-trash'},
            {id:'view', display:'View' , ctx: 'is-default', icon:'fa-search'},
            {id:'validate', display:'Validate' , ctx: 'is-default', icon:'fa-check'},
            {id:'enable', display:'Enable' , ctx: 'is-link', icon:'fa-check'},
            {id:'disable', display:'Disable' , ctx: 'is-default', icon:'fa-pencil-alt'},
            {id:'close_bet', display:'Close Bet' , ctx: 'is-warning', icon:'fa-pencil-alt'},
        ];

        function amountFormat(cell, formatterParams, onRendered){
            try{
                return currency(cell.getValue()).format({symbol:'',separator:','});
            } catch(err){
                return cell.getValue();
            }
        };



        function dateFormat(cell, formatterParams, onRendered){
            try{
                if(cell.getValue().length < 15){
                    return cell.getValue();
                } else {
                    return moment(cell.getValue()).utc().format('YYYY-MM-DD');;
                };
            } catch(err){
                return cell.getValue();
            }
        };

        function dateTimeFormat(cell, formatterParams, onRendered) {
            try {
                if (cell.getValue().length < 15) {
                    return cell.getValue();
                } else {
                    return moment(cell.getValue()).utcOffset(8).format('YYYY-MM-DD HH:mm:ss');
                }
            } catch (err) {
                return cell.getValue();
            }
        }
        
        
        const puller = (src, ids)=>{
            let arr = [];

            for (let i = 0; i < ids.length; i++){
                let id = ids[i];
                let q = src.find(item=>{
                    return item.id == id;
                });
                if (q){
                    arr.push(q);
                };
            };
            return arr;
        };

        // this.maintenance = {
        //     display:'Maintenance',
        //     components:['games',],
        //     toolbar:[],
        //     fields:[]
        // };        

        this.user = {
            display:'User',
            radio: puller(radioConfig, ['edit', 'delete']),
            components:['table', 'toolbar'],
            toolbar:puller(toolbarConfig, ['addRecord', 'download', 'print', 'sync']),
            fields:[
                {field: 'createdAt', title: 'Created Date', ui:'table/preview', type:'date',
                formatter:function(cell, formatterParams, onRendered){
                    try{
                        return new Date(cell.getValue()).toLocaleDateString()
                    } catch(err){
                        return cell.getValue();
                    }
                }},
                {field: 'username', title: 'Username', tag: 'input', type: 'text', ui: 'form/table/preview',form:['add-record']},
                {field: 'role', title: 'Role', tag: 'input', type: 'text', ui: 'form/table/preview'},
                {field: 'permission_id', title: 'Permission', type: 'text', tag: 'select-update-multiple', form:['add-record','edit-record'],ui: 'form/table/preview', },
                {field: 'role_id', title: 'Role ID', tag: 'input', type: 'text', ui: ''},
                {field: 'role_id', title: 'Role', type: 'text',tag: 'select-update', ui: '', form:['add-record']},
                {field: 'token', title: 'Token', tag: 'input', type: 'password', ui: 'table/preview', },
            ]
        };

        this.role = {
            display:'Role',
            radio: puller(radioConfig, ['edit', 'delete']),
            components:['table', 'toolbar'],
            toolbar:puller(toolbarConfig, ['addRecord', 'download', 'print', 'sync']),
            fields:[
                {field: 'createdAt', title: 'Created Date', ui:'table/preview', type:'date',
                formatter:function(cell, formatterParams, onRendered){
                    try{
                        return new Date(cell.getValue()).toLocaleDateString()
                    } catch(err){
                        return cell.getValue();
                    }
                }},
                {field: 'description', title: 'Description', tag: 'input', type: 'text', ui: 'form/table/preview',form:['add-record','edit-record']},
            ]
        };


        this.permission = {
            display:'Permission',
            radio: puller(radioConfig, ['edit', 'delete']),
            components:['table', 'toolbar'],
            toolbar:puller(toolbarConfig, ['addRecord', 'download', 'print', 'sync']),
            fields:[
                {field: 'createdAt', title: 'Created Date', ui:'table/preview', type:'date',
                formatter:function(cell, formatterParams, onRendered){
                    try{
                        return new Date(cell.getValue()).toLocaleDateString()
                    } catch(err){
                        return cell.getValue();
                    }
                }},
                {field: 'description', title: 'Description', tag: 'input', type: 'text', ui: 'form/table/preview',form:['add-record','edit-record']},
                {field: 'name', title: 'Name', tag: 'input', type: 'text', ui: 'form/table/preview',form:['add-record','edit-record']},
            ]
        };


        this.players = {
            display:'Players',
            radio: [],
            components:['table','toolbar'],
            toolbar:puller(toolbarConfig, ['filter', 'download',  'sync']),
            fields:[
                {field: 'date_registered', title: 'Registered',  type: 'text', ui: 'table/filter'},
                {field: 'member_id', title: 'Member ID',  type: 'text', ui: 'table/filter'},
                {field: 'mobile_no', title: 'Mobile No.',  type: 'text', ui: 'table'},
                {field: 'fullname', title: 'Full Name',  type: 'text', ui: 'table/filter'},
                {field: 'wallet', title: 'Wallet',  type: 'text', ui: 'table'},
                {field: 'app_id', title: 'App ID',  type: 'text', ui: 'table'},
                {field: 'token', title: 'Token',  type: 'text', ui: 'table'},
                {field: 'session_id', title: 'Session ID',  type: 'text', ui: 'table'},
                {field: 'logged_partner', title: 'Logged?',  type: 'text', ui: 'table'},
            ]
        };



        this.ggr = {
            display:'GGR',
            radio: [],
            filter:function(ctx){
                return puller(betFilterConfig, ctx);
            },
            components:['table', 'toolbar', 'ggr'],
            toolbar:puller(toolbarConfig, ['download',  'sync']),
            fields:[
                {field: 'settlement_date', title: 'Settlement Date', tag:'input',type: 'input', ui: 'table',
                formatter:dateFormat},
                {field: 'bet_count', title: 'Bet Count', tag:'input',type: 'text', ui: 'table'},
                {field: 'total_bet_amount', title: 'Bet Amount', tag:'input',type: 'text', ui: 'table',formatter:amountFormat},
                {field: 'total_payout_amount', title: 'Payout', tag:'input',type: 'text', ui: 'table',formatter:amountFormat},
                {field: 'amount_yield', title: 'GGR', tag:'input',type: 'text', ui: 'table',formatter:amountFormat},

                // {field:'date_type',title:'Date Type', tag:'select-auto', type:'text', form:['fuzzy'], options:[
                //     {display:'Date Range',value:'daterange'},
                //     {display:'Date Time',value:'datetime'},
                // ]},
                // {field:'',title:'', tag:'date-range', type:'text', form:['fuzzy']},


                {field:'bet_filter',title:'Game Provider', tag:'select-auto', type:'text', form:['fuzzy']},
                // {field:'channel_type',title:'Channel Type', tag:'select-auto-channel_type',type:'text', form:['fuzzy']},
                {field:'game_type',title:'Game Type', tag:'select-update-multiple',type:'text', form:['fuzzy'],options:[]},

                {field: 'datetime_from', title: 'Date From', tag:'input',type: 'datetime-local', ui: 'filter'},
                {field: 'datetime_to', title: 'Date To', tag:'input',type: 'datetime-local', ui: 'filter'},

                {field: 'start_datetime', title: 'Start Date/Time', tag:'input-start-datetime',type: 'datetime-local', placeholder:'end datetime',category:'datetime',form:['fuzzy']},
                {field: 'end_datetime', title: 'End Date/Time', tag:'input-end-datetime',type: 'datetime-local', placeholder:'start datetime',category:'datetime',form:['fuzzy']},
            ]
        };

        this.betting_logs = {
            display:'Logs',
            radio: puller(radioConfig, ['close_bet']),
            filter:function(ctx){
                return puller(betFilterConfig, ctx);
            },
            components:['table', 'toolbar', 'ggr'],
            toolbar:puller(toolbarConfig, ['download',  'sync']),
            fields:[


                {field: 'bet_id', title: 'Bet ID', tag:'input',type: 'input', ui: 'filter'},
                {field: 'datetime_from', title: 'Date From', tag:'input',type: 'datetime-local', ui: 'filter'},
                {field: 'datetime_to', title: 'Date To', tag:'input',type: 'datetime-local', ui: 'filter'},
                {field: 'date_sorting', title: 'Date Sorting', type:'text',tag: 'select-auto',options:[
                    {display:'latest', value:'latest'},
                    {display:'oldest', value:'oldest'},
                ], ui: 'filter'},

                {field: 'created_at', title: 'Created At', tag:'input', type: 'date', ui: 'table',formatter:dateTimeFormat},
                {field: 'closed_at', title: 'Closed At', type:'text',tag: 'date', ui: 'table',formatter:dateTimeFormat},
                {field: 'id', title: 'Bet ID',  type: 'text', tag:'input',ui: 'table/filter'},
                {field: 'username', title: 'Member ID', tag:'input',type: 'text', ui: 'table'},
                {field: 'username', title: 'Member ID', tag:'input',type: 'text', ui: 'filter'},
                {field: 'branch', title: 'Branch',  type: 'text', ui: 'table'},
                {field: 'game_type', title: 'Game Name',tag:'input',type: 'text', ui: 'table'},
                {field: 'game_type', title: 'Game Name',tag:'input',type: 'text', ui: 'filter'},
                {field: 'amount', title: 'Bet Amount',  type: 'text', ui: 'table',formatter:amountFormat},
                {field: 'amount_won', title: 'Total Payout',  type: 'text', ui: 'table',formatter:amountFormat},
                {field: 'jackpot_contribution', title: 'Jackpot Contribution',  type: 'text', ui: 'table',formatter:amountFormat},
                {field: 'status', title: 'Status',options:[
                    {display:'Settled', value:'1'},
                    {display:'Unsettled', value:'0'},
                ] , type: 'text', tag:'select-auto', ui: 'filter'},

                {field: 'start_datetime', title: 'Start Date/Time', tag:'input-start-datetime',type: 'datetime-local', placeholder:'end datetime',category:'datetime',form:['fuzzy']},
                // {field: 'start_datetime', title: 'Start Date/Time', tag:'input',type: 'datetime-local', placeholder:'end datetime',category:'datetime',form:['fuzzy']},
                // {field: 'end_datetime', title: 'End Date/Time', tag:'input',type: 'datetime-local', placeholder:'start datetime',category:'datetime',form:['fuzzy']},
                {field: 'end_datetime', title: 'End Date/Time', tag:'input-end-datetime',type: 'datetime-local', placeholder:'start datetime',category:'datetime',form:['fuzzy']},
                {field:'bet_filter',title:'Game Provider', tag:'select-auto', type:'text', form:['fuzzy']},
                // {field:'channel_type',title:'Channel Type', tag:'select-auto-channel_type',type:'text', form:['fuzzy']},
                {field:'game_type',title:'Game Type', tag:'select-update-multiple',type:'text', form:['fuzzy'],options:[]},
            ]
        };

        this.maintenance = {
            display:'Maintenance',
            radio: puller(radioConfig, ['enable', 'disable']),
            filter:function(ctx){
                return puller(betFilterConfig, ctx);
            },
            components:['table', 'toolbar', 'ggr'],
            toolbar:puller(toolbarConfig, ['upload',  'sync']),
            fields:[
                // {field: 'created_at', title: 'Created At', tag:'input', type: 'date', ui: 'table',formatter:dateTimeFormat},
                {field: 'updated_at', title: 'Updated At', type:'text',tag: 'date', ui: 'table',formatter:dateTimeFormat},
                {field: 'last_upload_at', title: 'Last Upload At', type:'text',tag: 'date', ui: 'table',formatter:dateTimeFormat},
                {field: 'id', title: 'ID',  type: 'text', tag:'input',ui: 'table/filter'},
                {field: 'name', title: 'Reference Name', tag:'input',type: 'text', ui: 'table'},
                {field: 'display', title: 'Display', tag:'input',type: 'text', ui: 'filter/table'},
                {field: 'is_active', title: 'Status', tag:'input',type: 'text', ui: 'filter/table'},

            ]
        };

        this.pull_bingo = {
            display:'Pull Bingo',
            filter:function(ctx){
                return puller(betFilterConfig, ctx);
            },
            components:['table', 'toolbar', 'ggr'],
            fields:[
                {field: 'id', title: 'ID',  type: 'text', tag:'input',ui: 'table/filter'},
                {field: 'pool_type', title: 'Pool Type', tag:'input',type: 'text', ui: 'table'},
                {field: 'data', title: 'data', tag:'input',type: 'text', ui: 'filter/table'},
                {field: 'num', title: 'Num', tag:'input',type: 'text', ui: 'filter/table'},
            ]
        };


        this.static_token = {
            display:'Static Token Text',
            toolbar:puller(toolbarConfig, ['reset']),
            // components:['resetModal']
        }
        this.static_token = {
            display:'Static Token Text',
            toolbar:puller(toolbarConfig, ['reset']),
            // components:['resetModal']
        }

        this.game_history = {
            display:'Game History',
            radio: [],
            components:['table', 'toolbar'],
            toolbar:puller(toolbarConfig, [  'sync']),
            fields:[
                {field: 'created_at', title: 'Created At',  type: 'text', ui: 'table/filter'},
                // {field: 'id', title: 'ID', type: 'text', ui: 'table'},
                // {field: 'site_branch_id', title: 'Site Branch ID',  type: 'text', ui: 'table'},
                {field: 'location', title: 'Location',  type: 'text', ui: 'table/filter'},
                {field: 'username', title: 'User Name',  type: 'text', ui: 'table/filter'},
                // {field: 'player_id', title: 'Player ID',  type: 'text', ui: 'table'},
                {field: 'game', title: 'Game',  type: 'text', ui: 'table/filter'},
                // {field: 'bet', title: 'Bet',  type: 'text', ui: 'table'},
                {field: 'extraball_cost', title: 'Extraball Cost',  type: 'text', ui: 'table'},
                {field: 'side_bet', title: 'Side Bet',  type: 'text', ui: 'table'},
                {field: 'win', title: 'Win',  type: 'text', ui: 'table'},
                {field: 'win_spent', title: 'Win Spent',  type: 'text', ui: 'table'},
                
                // {field: 'firs_bonus_feature_win', title: 'First Bonus',  type: 'text', ui: 'table'},
                // {field: 'second_bonus_feature_win', title: 'Second Bonus',  type: 'text', ui: 'table'},


                {field: 'jackpot_win', title: 'Jackpot Payout',  type: 'text', ui: 'table'},
                {field: 'credit_before', title: 'Credit Before',  type: 'text', ui: 'table'},
                {field: 'credit_after', title: 'Credit After',  type: 'text', ui: 'table'},


                // {field: 'game_start', title: 'Game Start',  type: 'text', ui: 'table'},
                // {field: 'game_end', title: 'Game End',  type: 'text', ui: 'table'},
                // {field: 'deleted_at', title: 'Deleted At',  type: 'text', ui: 'table'},
                // {field: 'updated_at', title: 'Updated At',  type: 'text', ui: 'table'},


                {field: 'cards', title: 'Cards',  type: 'text', ui: 'table'},
                {field: 'ball_list', title: 'Ball List',  type: 'text', ui: 'table'},
                {field: 'winning_cards_identification', title: 'Winning Cards',  type: 'text', ui: 'table'},
            ],
            reduce:[
                {field: 'jackpot_win', title: 'Jackpot Payout',  type: 'text', ui: 'table'},
                {field: 'credit_before', title: 'Credit Before',  type: 'text', ui: 'table'},
                {field: 'credit_after', title: 'Credit After',  type: 'text', ui: 'table'},
                {field: 'cards', title: 'Cards',  type: 'text', ui: 'table'},
                {field: 'ball_list', title: 'Ball List',  type: 'text', ui: 'table'},
                {field: 'winning_cards_identification', title: 'Winning Cards',  type: 'text', ui: 'table'},
            ]
        };
        
        this.login = {
            fields:[
                {field: 'username', title: 'Username', tag: 'input', type: 'text', ui: 'form',},
                {field: 'password', title: 'Password', tag: 'input', type: 'password', ui: 'form', },
            ]
        }

        this.tableSettings = {
            persistence:{
                // sort:true,
                // filter:true,
                // columns:true,
            },
            columnDefaults:{
                tooltip:true,  
            },       //show tool tips on cells
            headerFilterLiveFilterDelay:100, //wait 600ms from last keystroke before triggering filter
            index:'_id', //set the index of the data;
            addRowPos:'top',
            reactiveData:true,
            // clipboard:true,
            // clipboardPasteAction:"replace",
            printAsHtml:true,
            // printStyled: true,
            printRowRange:"selected",
            persistenceID:"breddas",

            pagination:true, //enable pagination
            paginationMode:"remote",
            paginationSize:10,
            paginationSizeSelector:[5, 10, 25, 50],

            filterMode:"remote",
                    //load row data from array
            layout:"fitDataFill",      //fit columns to width of table
            responsiveLayout:"collapse",

            addRowPos:"top",          //when adding a new row, add it to the top of the table
            movableColumns:true,      //allow column order to be changed
            resizableRows:true,       //allow row order to be changed
            // initialSort:[             //set the initial sort order of the data
            //     {column:"_id", dir:"des"},
            // ],
        };
    },
    handlers:{
        isConnected(obj){},
        getComponents({next, prev}){
            return {next: this.data[next]?.components ?? [], prev: this.data[prev]?.components ?? []};  
        },
        getToolbar(menu){
            return this.data[menu]?.toolbar ?? [];
        },
        getUiConfig(menu){
            let meta = this.data[menu];
            if (meta){
                let {display, toolbar, fields, radio, options, reduce} = meta;
                fields = fields.reduce((accu, iter)=>{
                    let o = {};
                    let f = {};
                    for (let key in iter){
                        let v = iter[key];
                        if(this.utils.isFunction(v)){
                            f[key] = v;
                        } else {
                            o[key] = v;
                        };
                    };
                    o = Object.assign(o, f);
                    accu.push(o);
                    return accu;
                },[]);



                let formFields = [];
                let tableFields = [];
                let filterFields = [];
                for (let f = 0; f < fields.length; f++){
                    let col = fields[f];
                    if (col.ui && col.ui.includes('table')){
                        let rem = ['tag', 'ui', 'type', 'options', 'readonly'];
                        let obj = {};
       
                        for (let key in col){
                            // console.log(key, rem);
                            if (!rem.includes(key)){
                                obj[key] = col[key];
                            }
                        };

                        tableFields.push(obj);
                    };
                    if (col.form || (col.ui && col.ui.includes('form'))){
                        formFields.push(col);
                    };
                    if (col.ui && col.ui.includes('filter')){
                        filterFields.push(col);
                    };
                };

                // console.log(323, tableFields);
                if (reduce){
                    return {menu, display, toolbar, formFields, tableFields,filterFields, reduce, radio, options:options||false}
                } else {
                    return {menu, display, toolbar, formFields, tableFields,filterFields, radio, options:options||false}
                };
            }
        },
        getTableSettings(){
            return {
                settings:this.data.tableSettings,
            };
        },
        getReduce(ui){
            let meta = this.data[ui];
            if (meta && meta.reduce){
                return meta.reduce;
            } else {
                return [];
            };
        },
        async getBetFilter(){
            return (await this.fire('getUserPermissions')).filter(item=>{
                return item.name.includes('bet.');
            }).map(item=>{
                
                let name = item.name.split('bet.')[1];
                return {display:item.description, _id:item._id,id:name};
            });
        },

    },
    subscribe:{
        getUiConfig:{
            components:['sidebar','table','form','radio','filter-form','fuzzy'],
            handler(menu){
                return this.fire.getUiConfig(menu);
            }
        },
        sidebar:{
            getComponents({next, prev}){
                return this.fire.getComponents({next, prev})
            },

        },
        toolbar:{
            getToolbar(menu){
                return this.fire.getToolbar(menu);
            }
        },
        table:{
            getTableSettings(){
                return this.fire.getTableSettings().settings;
            },
            getReduce(ui){
                if (ui){
                    return this.fire.getReduce(ui);
                } else {
                    return [];
                };
            },
        },
        bet_filter:{
            getBetFilter(){
                return this.fire.getBetFilter();
            }
        }
    },
})